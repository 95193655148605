<template>
	<header id="header" />

	<!-- Nav -->
	<nav id="nav">
		<ul>
			<li>
				<router-link :class="{ active: is_active[0] }" to="/account/#user-info">Utilisateur</router-link>
			</li>
			<li>
				<router-link :class="{ active: is_active[1] }" to="/account/#subscription">Abonnement</router-link>
			</li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main" class="page-account">
		<section id="user-info" class="main" v-show="this.is_active[0]">
			<v-form>
				<v-container>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field clearable label="Nom :" required variant="solo-filled" v-model="user_info.last_name" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field clearable label="Prénom :" required variant="solo-filled" v-model="user_info.first_name" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field label="Email :" readonly required variant="solo-filled" v-model="user_info.email" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field clearable label="Raison sociale :" required variant="solo-filled" v-model="user_info.company_name" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="6" md="6">
							<v-text-field label="Numéro de téléphone :" readonly required variant="solo-filled" v-model="user_info.phone_number" />
						</v-col>
						<v-col cols="6" md="6">
							<v-text-field label="Api key :" readonly required variant="solo-filled" v-model="api_key" />
						</v-col>
					</v-row>
					<v-row>
						<v-spacer />
						<v-btn @click="go_alfred()" size="large" text="Ouvrir Alfred" variant="flat" />
						<v-spacer />
					</v-row>
				</v-container>
			</v-form>
		</section>

		<section id="subscription" class="main" v-show="this.is_active[1]">
			<stripe-buy-button buy-button-id="buy_btn_1OqHrMGNFYzim8vIMvM6noLS" :customer-email="this.user_info.email" publishable-key="pk_test_51NuIOkGNFYzim8vIL50M0GO6b2KuMdJu2xsMd0ZF0RQ08iCQTlT2wFc7cu0i6WB9jWeVmykeTzqGx1bWDK4cBt75004UoHZe2C" />
		</section>
	</div>

</template>

<style>
div#main.page-account .main {
	border-top: none;
	padding-top: 2em;
}
</style>

<script>
	import Api from '@/Api.js'

	export default {
		beforeRouteUpdate(to) {
			this.is_active[0] = to.fullPath == "/account/#user-info" || to.hash == ""
			this.is_active[1] = to.fullPath == "/account/#subscription"
		},
		created() {
			let api = Api.get_instance()
			this.connected = api.has_token()

			api.do_get_user_info()
				.then((response) => {
					this.api_key = response.api_key
					this.user_info.company_name = response.metadata.company_name
					this.user_info.email = response.email
					this.user_info.first_name = response.metadata.first_name
					this.user_info.last_name = response.metadata.last_name
					this.user_info.phone_number = response.metadata.phone_number
				})
		},
		data() {
			return {
				api_key: null,
				user_info: {
					company_name: null,
					email: null,
					first_name: null,
					last_name: null,
					phone_number: null
				},
				is_active: [true, false]
			}
		},
		methods: {
			go_alfred() {
				this.$router.push({name: 'message'})
			}
		},
		mounted() {
			let js = document.createElement('script')
			js.setAttribute('async', '')
			js.setAttribute('src', 'https://js.stripe.com/v3/buy-button.js')
			document.querySelector('head').appendChild(js)

			this.is_active[0] = this.$route.fullPath == "/account/#user-info" || this.$route.hash == ""
			this.is_active[1] = this.$route.fullPath == "/account/#subscription"
		},
		name: 'AccountView'
	}
</script>
