<template>
	<header id="header" />

	<nav id="nav">
		<ul>
			<li><router-link :class="{ active: is_active[0] }" to="/account/message/#address-book">Carnet d'adresse</router-link></li>
			<li><router-link :class="{ active: is_active[1] }" to="/account/message/#message">Message</router-link></li>
		</ul>
	</nav>

	<!-- Main -->
	<div id="main">
		<section id="address-book" class="main" v-show="this.is_active[0]">
			<AddressBook @update-people="(n) => total = n" />
		</section>

		<section id="message" class="main" v-show="this.is_active[1]">
			<SmsMessage :total_peoples="total" />
		</section>
	</div>

</template>

<script>
	import AddressBook from '@/components/AddressBook.vue';
	import SmsMessage from '@/components/SmsMessage.vue';

	// @ is an alias to /src
	export default {
		beforeRouteUpdate(to) {
			this.is_active[0] = to.fullPath == "/account/message/#address-book" || to.hash == ""
			this.is_active[1] = to.fullPath == "/account/message/#message"
		},
		components: {
			AddressBook,
			SmsMessage
		},
		data() {
			return {
				is_active: [true, false],
				total: 0
			}
		},
		mounted() {
			this.is_active[0] = this.$route.fullPath == "/account/message/#address-book" || this.$route.hash == ""
			this.is_active[1] = this.$route.fullPath == "/account/message/#message"
		},
		name: 'MessageView'
	}
</script>
